import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import theme from "../theme"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"

const GeneralitiesAnneau = () => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Généralités Anneau" />
        <Typography
          variant="h4"
          color="inherit"
          style={{ margin: "20px 0px 0px" }}
        >
          Anneau gastrique
        </Typography>
        <Typography
          variant="h6"
          color="textSecondary"
          style={{ margin: "8px 0px 20px" }}
        >
          Technique restrictive
        </Typography>
        <Image alt="Anneau gastrique" filename="anneau.png" />
        <Typography
          variant="caption"
          color="inherit"
          style={{ textAlign: "center", display: "block", marginBottom: 20 }}
        >
          <a href="https://www.has-sante.fr/upload/docs/application/pdf/2009-09/fiche_technique_anneau_gastrique_080909.pdf">
            <i>
              https://www.has-sante.fr/upload/docs/application/pdf/2009-09/fiche_technique_anneau_gastrique_080909.pdf
            </i>
          </a>
        </Typography>
        <Typography variant="body1">
          L’objectif est de réduire le volume de l’estomac afin de limiter les
          ingesta, en plaçant un anneau autour de la partie supérieure de
          l’estomac, lui-même relié à un boitier placé sous la peau. La
          striction de l’anneau est modulable par injection d’un liquide dans le
          boitier. L’anneau peut être retiré au cours d’une nouvelle
          intervention en cas de complication, d’inefficacité ou sur demande du
          patient. Il s’agit de la seule technique ajustable et réversible.
        </Typography>
        <div style={{ width: "100%", marginTop: 20, textAlign: "center" }}>
          <Button href="/generalities">Revenir aux Généralités</Button>
        </div>
      </Layout>
    </ThemeProvider>
  </React.Fragment>
)

export default GeneralitiesAnneau
